import { useEffect, useCallback, useState } from 'react';
import { useIsMobileHook } from '../../hooks';
import styled from 'styled-components';
import { CLOUD_C } from '../../values';
import { Element } from 'react-scroll';
import { UserMeta } from './UserMeta';
import { theme } from '../../theme';
import { reviews } from './reviews';
import { Review } from './Review';
import { Bars } from './Bars';

export const CustomerReviews = () => {
  const { isMobile } = useIsMobileHook();

  const [interval, setIntervalRef] = useState<any | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { name, text, rating, image } = reviews[activeIndex];

  const title = `Customer reviews`;

  const initReviewsNavigation = useCallback(() => {
    setIntervalRef(
      setInterval(() => {
        setActiveIndex((index) =>
          index === reviews.length - 1 ? 0 : index + 1
        );
      }, 5000)
    );
  }, []);

  useEffect(() => {
    if (!interval) {
      initReviewsNavigation();
    }
    return () => clearInterval(interval);
  }, [interval, initReviewsNavigation]);

  return (
    <Wrapper name="reviews">
      {!isMobile ? <Cloud src={CLOUD_C} /> : null}
      <Center>
        <Title>{title}</Title>
        <Review {...{ text }} />
        <Content>
          <UserMeta {...{ name, image, rating }} />
          <Bars activeIndex={activeIndex} total={reviews.length} />
        </Content>
      </Center>
    </Wrapper>
  );
};

const Wrapper = styled(Element)`
  flex-direction: column;
  padding-bottom: 150px;
  position: relative;
  padding-top: 50px;
  display: flex;
  height: 85vh;
  width: 100%;
  @media (max-width: 415px) {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
const Cloud = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;
const Center = styled.div`
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;
  display: flex;
  margin: auto;
  height: 100%;
  width: 80%;
`;
const Title = styled.h2`
  font-family: Clash, sans-serif;
  justify-content: center;
  color: ${theme.white};
  flex-direction: row;
  font-weight: normal;
  font-size: 48px;
  display: flex;
  width: 100%;
  margin: 0;
  flex: 0;
  @media (max-width: 415px) {
    padding-bottom: 25px;
    font-size: 35px;
  }
`;
const Content = styled.div`
  justify-content: space-between;
  flex-direction: row;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  width: 80%;
  flex: 0.2;
  @media (max-width: 415px) {
    padding-top: 0;
    width: 80%;
  }
`;
