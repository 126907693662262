import { BRAINFISH_LOGO_IMAGE, POCKET_TUNER_URL } from '../../values';
import styled from 'styled-components';
import { theme } from '../../theme';
import React from 'react';

export const Footer = ({
  setIsTermsAndConditionsOpen,
  setIsPrivacyPolicyOpen
}: {
  setIsTermsAndConditionsOpen: (b: boolean) => void;
  setIsPrivacyPolicyOpen: (b: boolean) => void;
}) => {
  const slogan = `Research, innovation, testing, and customer feedback are what separate our products from the rest.`;
  const copyright = `Copyright © 2023 Brainfish LLC. All rights reserved`;
  const termsAndConditionsText = `Terms & Conditions`;
  const privacyPolicyText = `Privacy Policy`;
  const pocketTunerLinkText = `Pocket Tuner`;
  const productsLinkText = `Products`;
  const contactLinkText = `Contact`;
  const productsTitle = `Products`;
  const aboutLinkText = `About`;
  const siteTitle = `Site Map`;

  return (
    <>
      <Border />
      <Wrapper>
        <CompanySection>
          <Logo src={BRAINFISH_LOGO_IMAGE} alt="Brainfish Logo" />
          <Slogan>{slogan}</Slogan>
        </CompanySection>
        <LinksSection>
          <Row>
            <Column>
              <ColumnTitle>{productsTitle}</ColumnTitle>
              <Link onClick={() => window.open(POCKET_TUNER_URL, '_blank')}>
                {pocketTunerLinkText}
              </Link>
            </Column>
            <Column>
              <ColumnTitle>{siteTitle}</ColumnTitle>
              <Link onClick={() => false}>{aboutLinkText}</Link>
              <Link onClick={() => false}>{productsLinkText}</Link>
              <Link onClick={() => false}>{contactLinkText}</Link>
            </Column>
          </Row>
        </LinksSection>
      </Wrapper>
      <Legal>
        <Copyright>{copyright}</Copyright>
        <PrivacyPolicy onClick={() => setIsPrivacyPolicyOpen(true)}>
          {privacyPolicyText}
        </PrivacyPolicy>
        <Terms onClick={() => setIsTermsAndConditionsOpen(true)}>
          {termsAndConditionsText}
        </Terms>
      </Legal>
    </>
  );
};

const Border = styled.div`
  border-top: solid 1px rgba(251, 252, 250, 0.4);
  height: 10px;
`;
const Wrapper = styled.div`
  padding-bottom: 50px;
  flex-direction: row;
  position: relative;
  padding-top: 50px;
  max-width: 1440px;
  display: flex;
  height: 15vh;
  margin: auto;
  width: 80%;
  @media (max-width: 415px) {
    height: fit-content;
  }
`;
const CompanySection = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0.3;
  @media (max-width: 415px) {
    flex: 0.6;
  }
`;
const Logo = styled.img`
  align-self: flex-start;
  flex-direction: row;
  display: flex;
  width: 60%;
  flex: 0;
  @media (max-width: 415px) {
    width: 50%;
  }
`;
const Slogan = styled.p`
  font-family: Acumin Pro, sans-serif;
  align-self: flex-start;
  color: ${theme.white};
  flex-direction: row;
  font-weight: normal;
  line-height: 25px;
  font-size: 16px;
  display: flex;
  opacity: 0.4;
  width: 75%;
  @media (max-width: 415px) {
    font-size: 14px;
    width: 100%;
  }
`;
const LinksSection = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0.7;
  @media (max-width: 415px) {
    flex: 0.4;
  }
`;
const Row = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display: flex;
  width: 100%;
`;
const Column = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0.2;
  @media (max-width: 415px) {
    padding-right: 10px;
  }
`;
const ColumnTitle = styled.p`
  font-family: Acumin Pro, sans-serif;
  color: ${theme.white};
  padding-bottom: 5px;
  font-weight: normal;
  text-align: right;
  line-height: 50px;
  font-size: 20px;
  margin: 0;
  @media (max-width: 415px) {
    line-height: 40px;
    font-size: 14px;
  }
`;
const Link = styled.button`
  font-family: Acumin Pro, sans-serif;
  background: transparent;
  text-decoration: none;
  color: ${theme.white};
  font-weight: normal;
  line-height: 35px;
  text-align: right;
  font-size: 16px;
  opacity: 0.4;
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: ${theme.white};
    opacity: 1;
  }
  @media (max-width: 415px) {
    line-height: 25px;
    font-size: 14px;
  }
`;
const Legal = styled.div`
  margin: 15px auto 0 auto;
  padding-bottom: 50px;
  height: fit-content;
  max-width: 1440px;
  width: 80%;
  @media (max-width: 415px) {
    padding-bottom: 10px;
    width: 95%;
  }
`;
const legalCss = `
  font-family: Acumin Pro, sans-serif;
  background: transparent;
  color: ${theme.white};
  font-weight: normal;
  position: relative;
  font-size: 12px;
  border: none;
  opacity: 0.4;
  padding: 0;
`;
const Copyright = styled.span`
  float: left;
  ${legalCss};
  @media (max-width: 415px) {
    padding-bottom: 5px;
    font-size: 10px;
    clear: both;
  }
`;
const PrivacyPolicy = styled.button`
  float: right;
  ${legalCss}
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  @media (max-width: 415px) {
    padding-bottom: 5px;
    font-size: 10px;
    float: left;
    clear: both;
  }
`;
const Terms = styled.button`
  margin-right: 125px;
  float: right;
  ${legalCss}
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  @media (max-width: 415px) {
    margin-right: 2px;
    font-size: 10px;
    float: left;
    clear: both;
  }
`;
