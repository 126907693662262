import { useIsMobileHook } from '../../hooks';
import { PortfolioItemProps } from './types';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Arrow } from '../svgs';
import React from 'react';

export const Item = ({
  parentHeight,
  background,
  onClick,
  image
}: PortfolioItemProps) => {
  const { isMobile } = useIsMobileHook();
  const divisor = isMobile ? 1.75 : 2;
  const itemHeight = parentHeight ? parentHeight / divisor : 0;

  return Boolean(itemHeight) ? (
    <Wrapper $dimension={itemHeight} $image={background}>
      <Button onClick={onClick}>
        <ViewMoreContainer>
          <ViewMoreLink color={theme.primary} />
        </ViewMoreContainer>
        <AppLogo src={image}></AppLogo>
      </Button>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div<{ $dimension: number; $image: string }>`
  background-image: url(${(props) => props.$image});
  height: ${(props) => props.$dimension}px;
  width: ${(props) => props.$dimension}px;
  justify-content: space-between;
  background-repeat: repeat;
  background-size: cover;
  box-sizing: border-box;
  cursor: pointer;
  padding: 35px;
  @media (max-width: 415px) {
    flex-direction: row;
    align-self: center;
  }
`;
const Button = styled.button`
  background: rgba(1, 1, 20, 90%);
  flex-direction: column;
  box-shadow: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
`;
const ViewMoreContainer = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 5px;
  cursor: pointer;
  display: flex;
  width: 100%;
`;
const ViewMoreLink = styled(Arrow)`
  cursor: pointer;
`;
const AppLogo = styled.img`
  flex-direction: row;
  border-radius: 2px;
  cursor: pointer;
  max-width: 72%;
  display: flex;
  margin: auto;
`;
