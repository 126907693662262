import {
  CLOUD_B,
  POCKET_TUNER_IMAGE,
  POCKET_TUNER_URL,
  PRODUCT_BACKGROUND_B
} from '../../values';
import { useEffect, useRef, useState } from 'react';
import { useIsMobileHook } from '../../hooks';
import { PortfolioItemProps } from './types';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { theme } from '../../theme';
import { Item } from './Item';

export const Products = () => {
  const { isMobile } = useIsMobileHook();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(
    wrapperRef?.current?.offsetHeight ?? 0
  );

  const title = 'Our products';

  const updateParentHeight = () => {
    if (wrapperRef?.current?.offsetHeight) {
      setHeight(wrapperRef?.current?.offsetHeight);
    }
  };

  useEffect(() => {
    updateParentHeight();
    window.onresize = () => updateParentHeight();
  }, []);

  const portfolioItems: PortfolioItemProps[] = [
    {
      onClick: () => window.open(POCKET_TUNER_URL, '_blank'),
      background: PRODUCT_BACKGROUND_B,
      image: POCKET_TUNER_IMAGE,
      parentHeight: height
    }
  ];

  return (
    <Element name="products" style={{ position: 'relative' }}>
      {!isMobile ? <Cloud src={CLOUD_B} /> : null}
      <Wrapper ref={wrapperRef}>
        <Title>{title}</Title>
        <Content>
          {portfolioItems.map((p, i) => (
            <Item {...p} key={`${i}-product-image`} />
          ))}
        </Content>
      </Wrapper>
    </Element>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  padding-bottom: 150px;
  padding-top: 50px;
  position: relative;
  min-height: 75vh;
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 1440px;
  @media (max-width: 415px) {
    padding-bottom: 50px;
    padding-top: 25px;
    min-height: 50vh;
    height: unset;
  }
`;
const Title = styled.h2`
  font-family: Clash, sans-serif;
  justify-content: center;
  color: ${theme.white};
  flex-direction: row;
  font-weight: normal;
  margin-bottom: 5%;
  font-size: 48px;
  display: flex;
  width: 100%;
  @media (max-width: 415px) {
    padding-bottom: 25px;
    font-size: 35px;
  }
`;
const Content = styled.div`
  justify-content: center;
  max-width: 1440px;
  display: flex;
  height: 90%;
  width: 100%;
  gap: 75px;
  @media (max-width: 415px) {
    flex-direction: column;
    font-size: 35px;
    gap: 25px;
  }
`;
const Cloud = styled.img`
  position: absolute;
  left: 0;
  top: 15%;
`;
