import styled from 'styled-components';
import { theme } from '../theme';

export const TermsModal = ({
  setIsModalOpen
}: {
  setIsModalOpen: (b: boolean) => void;
}) => (
  <Modal>
    <Content>
      <CloseButton onClick={() => setIsModalOpen(false)}>X</CloseButton>
      <TextContent>
        <Title>Terms & Conditions for Brainfish LLC</Title>
        <br />
        <Title>Last Updated: 09/16/2023</Title>
        <br />
        <Title>1. Acceptance of Terms</Title>
        <Text>
          By accessing and using the Brainfish LLC website ("Site"), you agree
          to comply with and be bound by these Terms & Conditions. If you do not
          agree to these terms, please do not use the Site.
        </Text>
        <br />
        <Title>2. Intellectual Property</Title>
        <Text>
          All content, graphics, user interfaces, visual interfaces,
          photographs, trademarks, logos, and computer code (collectively,
          "Content"), including but not limited to the design, structure,
          selection, coordination, expression, and arrangement of such Content,
          contained on the Site is owned, controlled, or licensed by or to
          Brainfish LLC, and is protected by trade dress, copyright, patent, and
          trademark laws.
        </Text>
        <br />
        <Title>3. User Conduct</Title>
        <Text>You agree not to use the Site in a way that:</Text>
        <Text>Violates any local, state, national, or international law.</Text>
        <Text>Harms, threatens, or harasses another person.</Text>
        <Text>
          Interferes with or disrupts the Site or servers connected to the Site.
        </Text>
        <br />
        <Title>4. Limitation of Liability</Title>
        <Text>
          Brainfish LLC shall not be liable for any direct, indirect,
          incidental, special, or consequential damages resulting from the use
          or inability to use the Site or for the cost of procurement of
          substitute services.
        </Text>
        <br />
        <Title>5. Termination</Title>
        <Text>
          Brainfish LLC reserves the right to terminate your access to the Site
          at its sole discretion, without notice, for conduct that Brainfish LLC
          believes violates these Terms & Conditions or is harmful to other
          users, Brainfish LLC, or third parties, or for any other reason.
        </Text>
        <br />
        <Title>6. Changes to Terms</Title>
        <Text>
          Brainfish LLC reserves the right to change these Terms & Conditions at
          any time. Updated versions of the Terms & Conditions will appear on
          this Site and are effective immediately. Continued use of the Site
          after any such changes constitutes your consent to such changes.
        </Text>
        <br />
        <Title>7. Contact</Title>
        <Text>
          For any questions regarding these Terms & Conditions, please contact
          Brainfish LLC at: admin@brainfish.io.
        </Text>
        <br />
        <CloseButtonBottom onClick={() => setIsModalOpen(false)}>
          CLOSE
        </CloseButtonBottom>
      </TextContent>
    </Content>
  </Modal>
);

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;
const Content = styled.div`
  background-color: ${theme.white};
  transform: translate(-50%, -50%);
  border-radius: 15px;
  overflow-y: scroll;
  position: fixed;
  padding: 5%;
  height: 50%;
  width: 50%;
  left: 50%;
  top: 50%;
`;
const CloseButton = styled.button`
  position: absolute;
  background: none;
  font-size: 24px;
  cursor: pointer;
  border: none;
  right: 3%;
  top: 5%;
`;
const TextContent = styled.div`
  padding-top: 20px;
`;
const Title = styled.p`
  font-family: Acumin Pro, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: black;
`;
const Text = styled.p`
  font-family: Acumin Pro, sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: black;
`;
const CloseButtonBottom = styled.button`
  background-color: ${theme.background};
  font-family: Acumin, sans-serif;
  text-transform: uppercase;
  color: ${theme.white};
  border-radius: 20px;
  position: relative;
  width: fit-content;
  padding-top: 0.25%;
  padding-left: 5%;
  padding-right: 5%;
  line-height: 50px;
  font-size: 16px;
  float: right;
  border: none;
  height: 50px;
  &:hover {
    background-color: ${theme.primary};
    cursor: pointer;
  }
`;
