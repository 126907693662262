import { setBrainfishFormRequest } from '../../api';
import { useIsMobileHook } from '../../hooks';
import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Arrow } from '../svgs';
import { Formik } from 'formik';
import { SchemaOf } from 'yup';
import * as yup from 'yup';

interface FormSchemaType {
  description: string;
  subject: string;
  email: string;
  name: string;
}

export const schema: SchemaOf<FormSchemaType> = yup.object().shape({
  description: yup.string().required().min(25).max(500),
  subject: yup.string().required().min(4).max(25),
  email: yup.string().email().required(),
  name: yup.string().min(2).required()
});

export const Form = () => {
  const { isMobile } = useIsMobileHook();

  const [isSent, setIsSent] = useState<boolean>(false);
  const [isBot, setIsBot] = useState<boolean>(false);

  const formSuccessText = `Thank you for your request. We will be in touch within the next business day.`;
  const recaptchaError = `Unable to verify recaptcha. Please try again later.`;

  const onFormSubmit = async (values: FormSchemaType) => {
    // @ts-ignore
    if (Boolean(grecaptcha.getResponse(greResponse))) {
      const res = await setBrainfishFormRequest(values);
      setIsSent(Boolean(res));
      return;
    }
    setIsBot(true);
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
      window.location.reload();
    }, 3500);
  };

  if (isBot) {
    return <FormSubmitText>{recaptchaError}</FormSubmitText>;
  }
  if (isSent) {
    return <FormSubmitText>{formSuccessText}</FormSubmitText>;
  }

  return (
    <Wrapper>
      <Formik
        onSubmit={(values) => onFormSubmit(values)}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          description: '',
          subject: '',
          email: '',
          name: ''
        }}>
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          isValid,
          errors,
          values
        }) => {
          const submitAndCaptcha = (
            <>
              <ReCaptcha id="recaptcha-container" />
              <SubmitButton
                disabled={
                  !Boolean(Object.keys(touched).length) ||
                  isSubmitting ||
                  !isValid
                }
                type="submit">
                Send
                <SendArrow color={theme.primary} />
              </SubmitButton>
            </>
          );
          const submitContent = isMobile ? (
            submitAndCaptcha
          ) : (
            <Row>{submitAndCaptcha}</Row>
          );

          return (
            <ContactForm onSubmit={handleSubmit}>
              <Row $align="space-between">
                <TextInput
                  $hasError={Boolean(errors.name && touched.name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name*"
                  autoComplete="off"
                  type="text"
                  id="name"
                />
                <TextInput
                  $hasError={Boolean(errors.email && touched.email)}
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Email*"
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="email"
                  id="email"
                />
              </Row>
              <Row>
                <TextInput
                  $hasError={Boolean(errors.subject && touched.subject)}
                  placeholder="Subject*"
                  onChange={handleChange}
                  value={values.subject}
                  onBlur={handleBlur}
                  autoComplete="off"
                  $isFullWidth
                  id="subject"
                  type="text"
                />
              </Row>
              <Row>
                <TextArea
                  $hasError={Boolean(errors.description && touched.description)}
                  placeholder="Description*"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  id="description"
                  rows={isMobile ? 7 : 10}
                />
              </Row>
              {submitContent}
            </ContactForm>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0.5;
  @media (max-width: 415px) {
    flex: 1;
  }
`;
const ContactForm = styled.form`
  background-color: ${theme.background};
  flex-direction: column;
  height: fit-content;
  margin: 0 auto;
  padding: 0 5%;
  display: flex;
  width: 30vw;
  flex: 0.75;
  @media (max-width: 415px) {
    padding: 25px 0;
    width: 95%;
    flex: 1;
  }
`;
const Row = styled.div<{ $align?: string }>`
  justify-content: ${(props) => props.$align ?? 'flex-start'};
  padding-bottom: 50px;
  flex-direction: row;
  position: relative;
  display: flex;
  width: 100%;
  gap: 25px;
  @media (max-width: 415px) {
    gap: 10px;
  }
`;
const TextInput = styled.input<{ $isFullWidth?: boolean; $hasError?: boolean }>`
  ${(props) => (props.$isFullWidth ? `width: 100%` : `flex: 0.5`)};
  border-bottom: ${(props) =>
      props.$hasError ? theme.primary : 'rgba(251 252 250, 0.4)'}
    solid 1px;
  background: ${theme.background};
  font-family: Arial, sans-serif;
  color: ${theme.white};
  border-right: none;
  border-left: none;
  border-top: none;
  font-size: 1rem;
  outline: none;
  padding: 2%;
  &::placeholder {
    color: ${(props) => (props.$hasError ? theme.primary : theme.white)};
    opacity: ${(props) => (props.$hasError ? 1 : 0.4)};
  }
  @media (max-width: 415px) {
    width: 100%;
  }
`;
const TextArea = styled.textarea<{ $hasError?: boolean }>`
  border-bottom: ${(props) =>
      props.$hasError ? theme.primary : 'rgba(251 252 250, 0.4)'}
    solid 1px;
  background: ${theme.background};
  font-family: Arial, sans-serif;
  color: ${theme.white};
  border-right: none;
  border-left: none;
  border-top: none;
  font-size: 1rem;
  outline: none;
  padding: 2%;
  flex: 1;
  &::placeholder {
    color: ${(props) => (props.$hasError ? theme.primary : theme.white)};
    opacity: ${(props) => (props.$hasError ? 1 : 0.4)};
  }
`;
const ReCaptcha = styled.div`
  display: flex;
  @media (max-width: 415px) {
    padding-bottom: 15px;
    flex-direction: row;
    width: 100%;
  }
`;
const SubmitButton = styled.button`
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  background-color: ${theme.white};
  font-family: Acumin, sans-serif;
  color: ${theme.background};
  text-transform: uppercase;
  align-self: flex-end;
  border-radius: 25px;
  align-items: center;
  width: fit-content;
  padding-top: 0.25%;
  padding-right: 5%;
  line-height: 50px;
  padding-left: 5%;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  border: none;
  height: 50px;
  margin: auto;
  @media (max-width: 415px) {
    justify-content: flex-end;
    flex-direction: row;
    margin: 0;
  }
`;
const SendArrow = styled(Arrow)`
  margin: 3px;
`;
const FormSubmitText = styled.p`
  font-family: Acumin, sans-serif;
  justify-content: flex-start;
  color: ${theme.primary};
  flex-direction: column;
  padding-top: 138px;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex: 0.5;
`;
