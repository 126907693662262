import styled from 'styled-components';
import { theme } from '../theme';

export const PrivacyPolicyModal = ({
  setIsModalOpen
}: {
  setIsModalOpen: (b: boolean) => void;
}) => (
  <Modal>
    <Content>
      <CloseButton onClick={() => setIsModalOpen(false)}>X</CloseButton>
      <TextContent>
        <Title>Privacy Policy for Brainfish, LLC</Title>
        <br />
        <Title>Last Updated: 09/16/2023</Title>
        <br />
        <Title>1. Introduction</Title>
        <Text>
          Welcome to Brainfish, LLC ("we", "our", or "us"). We respect your
          privacy and are committed to protecting it through our compliance with
          this policy.
        </Text>
        <Text>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit our website, Brainfish, and our
          practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </Text>
        <br />
        <Title>2. Information We Collect</Title>
        <Text>
          We collect several types of information from and about users of our
          website, including:
        </Text>
        <Text>
          Personal Information: This includes your name and email address, which
          you provide when filling out our contact form.
        </Text>
        <Text>
          Feedback/Inquiries: Information you provide in the description
          textarea for product feedback or general inquiries.
        </Text>
        <br />
        <Title>3. How We Use Your Information</Title>
        <Text>We use the information you provide to: </Text>
        <Text>Respond to your inquiries or feedback.</Text>
        <Text> Provide information about our products or services.</Text>
        <Text>
          Notify you about changes to our website or any products or services we
          offer or provide.
        </Text>
        <br />
        <Title>4. Disclosure of Your Information</Title>
        <Text>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties. This does not include trusted third parties who
          assist us in operating our website, conducting our business, or
          servicing you, so long as those parties agree to keep this information
          confidential.
        </Text>
        <br />
        <Title>5. Data Security</Title>
        <Text>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure.
        </Text>
        <br />
        <Title>6. Changes to Our Privacy Policy</Title>
        <Text>
          We may update our Privacy Policy from time to time. All changes are
          effective immediately when we post them. Your continued use of the
          website after the revised Privacy Policy has become effective
          indicates that you have read, understood, and agreed to the current
          version of the Privacy Policy.
        </Text>
        <br />
        <Title>7. Contact Information</Title>
        <Text>
          For questions or comments about this Privacy Policy and our privacy
          practices, contact us at: admin@brainfish.io.
        </Text>
        <br />
        <CloseButtonBottom onClick={() => setIsModalOpen(false)}>
          CLOSE
        </CloseButtonBottom>
      </TextContent>
    </Content>
  </Modal>
);

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;
const Content = styled.div`
  background-color: ${theme.white};
  transform: translate(-50%, -50%);
  border-radius: 15px;
  overflow-y: scroll;
  position: fixed;
  padding: 5%;
  height: 50%;
  width: 50%;
  left: 50%;
  top: 50%;
`;
const CloseButton = styled.button`
  position: absolute;
  background: none;
  font-size: 24px;
  cursor: pointer;
  border: none;
  right: 3%;
  top: 5%;
`;
const TextContent = styled.div`
  padding-top: 20px;
`;
const Title = styled.p`
  font-family: Acumin Pro, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: black;
`;
const Text = styled.p`
  font-family: Acumin Pro, sans-serif;
  font-weight: normal;
  font-size: 20px;
  color: black;
`;
const CloseButtonBottom = styled.button`
  background-color: ${theme.background};
  font-family: Acumin, sans-serif;
  text-transform: uppercase;
  color: ${theme.white};
  border-radius: 20px;
  position: relative;
  width: fit-content;
  padding-top: 0.25%;
  padding-left: 5%;
  padding-right: 5%;
  line-height: 50px;
  font-size: 16px;
  float: right;
  border: none;
  height: 50px;
  &:hover {
    background-color: ${theme.primary};
    cursor: pointer;
  }
`;
