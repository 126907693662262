import { PrivacyPolicyModal } from './components/PrivacyPolicyModal';
import { ParallaxContainer } from './components/ParallaxContainer';
import { CustomerReviews } from './components/CustomerReviews';
import styled, { ThemeProvider } from 'styled-components';
import { BANNER_A, BANNER_B, BANNER_C } from './values';
import { TermsModal } from './components/TermsModal';
import { Products } from './components/Products';
import { Contact, NavBar } from './components';
import { Footer } from './components/Footer';
import { About } from './components/About';
import { Hero } from './components/Hero';
import React, { useState } from 'react';
import { theme } from './theme';

const App = () => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] =
    useState<boolean>(false);
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState<boolean>(false);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper $isScrollable={!isPrivacyPolicyOpen}>
        <NavBar />
        <Hero />
        <ParallaxContainer image={BANNER_A} />
        <About />
        <ParallaxContainer image={BANNER_B} />
        <Products />
        <CustomerReviews />
        <ParallaxContainer image={BANNER_C} />
        <Contact />
        <Footer
          setIsTermsAndConditionsOpen={setIsTermsAndConditionsOpen}
          setIsPrivacyPolicyOpen={setIsPrivacyPolicyOpen}
        />
      </Wrapper>
      {isPrivacyPolicyOpen ? (
        <PrivacyPolicyModal setIsModalOpen={setIsPrivacyPolicyOpen} />
      ) : null}
      {isTermsAndConditionsOpen ? (
        <TermsModal setIsModalOpen={setIsTermsAndConditionsOpen} />
      ) : null}
    </ThemeProvider>
  );
};

export default App;

const Wrapper = styled.div<{ $isScrollable: boolean }>`
  background-color: ${theme.background};
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  width: 100vw;
`;
