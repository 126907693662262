import styled from 'styled-components';
import { ReviewType } from './types';
import { RatingStar } from '../svgs';
import { theme } from '../../theme';

export const UserMeta = ({ rating, image, name }: Omit<ReviewType, 'text'>) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <ImageWrapper>
          <Image src={image} alt={`Brainfish review from ${name}`} />
        </ImageWrapper>
        <ProfileContent>
          <Name>{name}</Name>
          <RatingWrapper>
            <Star />
            <Rating>{rating}</Rating>
          </RatingWrapper>
        </ProfileContent>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex: 0.5;
`;
const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;
const ImageWrapper = styled.div`
  flex-direction: column;
  margin: auto 0;
  display: flex;
  flex: 0.1;
`;
const Image = styled.img`
  background-color: ${theme.white};
  border-radius: 60px;
  height: 75px;
  width: 75px;
  @media (max-width: 415px) {
    height: 50px;
    width: 50px;
  }
`;
const ProfileContent = styled.div`
  flex-direction: column;
  padding-left: 15px;
  margin: auto 0;
  display: flex;
  flex: 0.7;
  @media (max-width: 415px) {
    padding-left: 10px;
    padding-top: 15px;
  }
`;
const Name = styled.p`
  font-family: Acumin, sans-serif;
  color: ${theme.white};
  font-style: italic;
  font-size: 38px;
  margin: 0;
  @media (max-width: 415px) {
    font-size: 25px;
  }
`;
const RatingWrapper = styled.div``;
const Star = styled(RatingStar)``;
const Rating = styled.span`
  font-family: Acumin Pro, sans-serif;
  color: ${theme.white};
  padding-left: 5px;
  font-weight: 400;
  font-size: 23px;
  opacity: 0.4;
  @media (max-width: 415px) {
    font-size: 18px;
  }
`;
