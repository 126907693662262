import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import styled from 'styled-components';

export const ParallaxContainer = ({ image }: { image: string }) => (
  <ParallaxProvider>
    <Banner layers={[{ image, speed: -15 }]} className="aspect-[2/1]" />
  </ParallaxProvider>
);
const Banner = styled(ParallaxBanner)`
  height: 25vh;
  width: 100vw;
  @media (max-width: 415px) {
    height: 15vh;
  }
`;
