import styled from 'styled-components';
import { theme } from '../../theme';

export const Bars = ({
  activeIndex,
  total
}: {
  activeIndex: number;
  total: number;
}) => (
  <Wrapper>
    <Row>
      {Array.from({ length: total }).map((_, index) => (
        <Bar $active={activeIndex === index} key={`bar-${index}`} />
      ))}
    </Row>
  </Wrapper>
);

const Wrapper = styled.div`
  flex-direction: column;
  padding-right: 50px;
  display: flex;
  height: 100px;
  flex: 0.5;
  @media (max-width: 415px) {
    padding-right: 0;
  }
`;
const Row = styled.div`
  justify-content: flex-end;
  flex-direction: row;
  display: flex;
  height: 100%;
  gap: 5px;
`;
const Bar = styled.div<{ $active: boolean }>`
  background-color: ${(props) => (props.$active ? theme.primary : theme.white)};
  opacity: ${(props) => (props.$active ? 1.0 : 0.4)};
  flex-direction: column;
  align-self: center;
  display: flex;
  height: 5px;
  width: 75px;
  @media (max-width: 415px) {
    width: 25px;
  }
`;
