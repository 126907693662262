export const POCKET_TUNER_URL = `https://play.google.com/store/apps/details?id=com.pocketTuner`;
export const BRAINFISH_LOGO_IMAGE = 'brainfish-logo-small.png';
export const PROFIT_PING_URL = 'https://profitping.app';
export const PROFIT_PING_LOGO = `profit-ping-logo.png`;
export const PRODUCT_BACKGROUND_A = `product-background-a.png`;
export const PRODUCT_BACKGROUND_B = `product-background-b.png`;
export const POCKET_TUNER_IMAGE = `pocket-tuner.png`;
export const REVIEW_IMAGE_A = `review-image-a.png`;
export const REVIEW_IMAGE_B = `review-image-b.png`;
export const REVIEW_IMAGE_C = `review-image-c.png`;
export const ABOUT_US_IMAGE = `about-us.jpg`;
export const BANNER_A = `banner-a.png`;
export const BANNER_B = `banner-b.png`;
export const BANNER_C = `banner-c.png`;
export const CLOUD_A = `cloud-a.png`;
export const CLOUD_B = `cloud-b.png`;
export const CLOUD_C = `cloud-c.png`;
export const CLOUD_D = `cloud-d.png`;
