import styled from 'styled-components';
import { ReviewType } from './types';
import { theme } from '../../theme';

export const Review = ({ text }: Pick<ReviewType, 'text'>) => (
  <Wrapper>
    <Quote>"</Quote>
    <Text>{text}</Text>
  </Wrapper>
);

const Wrapper = styled.div`
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  width: 80%;
  @media (max-width: 415px) {
    width: 95%;
  }
`;
const Quote = styled.span`
  font-family: Clash, sans-serif;
  flex-direction: column;
  color: ${theme.white};
  font-size: 150px;
  text-align: left;
  display: flex;
  opacity: 0.4;
  flex: 0;
  @media (max-width: 415px) {
    font-size: 75px;
  }
`;
const Text = styled.p`
  font-family: Acumin, sans-serif;
  flex-direction: column;
  color: ${theme.white};
  font-style: italic;
  padding-top: 75px;
  line-height: 57px;
  margin-bottom: 0;
  text-align: left;
  font-size: 40px;
  display: flex;
  height: 150px;
  opacity: 0.4;
  @media (max-width: 415px) {
    line-height: 30px;
    padding-top: 25px;
    font-size: 25px;
    height: 225px;
  }
`;
