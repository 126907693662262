import styled from 'styled-components';
import { theme } from '../../theme';

const d = `M10.9231 1.5C10.9231 0.990199 10.5098 0.576923 10 0.576923L1.69231 0.57692C1.18251 0.57692 0.769235 0.990195 0.769235 1.5C0.769235 2.0098 1.18251 2.42307 1.69231 2.42307L9.07693 2.42308L9.07692 9.80769C9.07692 10.3175 9.4902 10.7308 10 10.7308C10.5098 10.7308 10.9231 10.3175 10.9231 9.80769L10.9231 1.5ZM2.65271 10.1527L10.6527 2.15271L9.34729 0.847286L1.34729 8.84729L2.65271 10.1527Z`;

export const Arrow = ({
  height,
  color,
  width
}: {
  height?: number;
  width?: number;
  color?: string;
}) => (
  <SVG xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
    viewBox="0 0 11 11" height="11" width="11" fill="none"
    <path fill={color ?? theme.white} d={d} />
  </SVG>
);
const SVG = styled.svg<{ height?: number; width?: number }>`
  height: ${(props) => props.height ?? 15}px;
  width: ${(props) => props.width ?? 15}px;
  padding-left: 5px;
`;
