import { useIsMobileHook } from '../../hooks';
import styled from 'styled-components';
import { CLOUD_D } from '../../values';
import { Element } from 'react-scroll';
import { Content } from './Content';
import { Form } from './Form';
import React from 'react';

export const Contact = () => {
  const { isMobile } = useIsMobileHook();

  return (
    <Wrapper name="contact">
      {!isMobile ? <Cloud src={CLOUD_D} /> : null}
      <Center>
        <Content />
        <Form />
      </Center>
    </Wrapper>
  );
};

const Wrapper = styled(Element)`
  position: relative;
  padding-top: 75px;
  min-height: 85vh;
  display: flex;
  margin: auto;
  width: 100%;
  @media (max-width: 415px) {
    min-height: 95vh;
  }
`;
const Cloud = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;
const Center = styled.div`
  flex-direction: row;
  max-width: 1440px;
  display: flex;
  margin: auto;
  width: 80%;
  @media (max-width: 415px) {
    flex-direction: column;
  }
`;
