import { useEffect, useState } from 'react';

export const useIsMobileHook = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 416);

  useEffect(() => {
    window.onresize = () => setIsMobile(window.innerWidth < 416);
  }, []);

  return { isMobile };
};
