import { BRAINFISH_LOGO_IMAGE } from '../../values';
import React, { useMemo, useState } from 'react';
import { useIsMobileHook } from '../../hooks';
import { MenuIcon } from '../svgs/MenuIcon';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { theme } from '../../theme';

const linkProps = {
  activeClass: 'active',
  duration: 500,
  smooth: true,
  offset: -100,
  spy: true
};

export const NavBar = () => {
  const { isMobile } = useIsMobileHook();
  const [isMenuDisplayed, setIsMenuDisplayed] = useState<boolean>(!isMobile);

  const navOptions = useMemo(
    () => (
      <NavOptions>
        <NavLink
          onClick={() => setIsMenuDisplayed((i) => !i)}
          to="home"
          {...linkProps}>
          Home
        </NavLink>
        <NavLink
          onClick={() => setIsMenuDisplayed((i) => !i)}
          to="about"
          {...linkProps}>
          About
        </NavLink>
        <NavLink
          onClick={() => setIsMenuDisplayed((i) => !i)}
          to="products"
          {...linkProps}>
          Products
        </NavLink>
        <NavLink
          onClick={() => setIsMenuDisplayed((i) => !i)}
          to="reviews"
          {...linkProps}>
          Reviews
        </NavLink>
        <NavLink
          onClick={() => setIsMenuDisplayed((i) => !i)}
          to="contact"
          {...linkProps}>
          Contact
        </NavLink>
      </NavOptions>
    ),
    []
  );
  const content = isMobile ? null : navOptions;

  return (
    <>
      <Nav>
        <Center>
          <Logo src={BRAINFISH_LOGO_IMAGE} alt="Brainfish Logo" />
          {content}
          {isMobile ? (
            <MenuButton onClick={() => setIsMenuDisplayed((i) => !i)}>
              <MenuIcon />
            </MenuButton>
          ) : null}
        </Center>
      </Nav>
      {isMobile && isMenuDisplayed ? (
        <MobileMenu id="mobile-menu">{navOptions}</MobileMenu>
      ) : null}
    </>
  );
};

const Nav = styled.div`
  background-color: ${theme.background};
  justify-content: center;
  flex-direction: row;
  align-self: center;
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 9;
  @media (max-width: 415px) {
    padding: 10px;
  }
`;
const MobileMenu = styled.div`
  background-color: ${theme.background};
  flex-direction: column;
  align-self: flex-end;
  width: fit-content;
  margin-top: 50px;
  position: fixed;
  display: flex;
  height: auto;
  z-index: 9;
`;
const MenuButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
`;
const Center = styled.div`
  justify-content: space-between;
  justify-self: center;
  flex-direction: row;
  max-width: 1440px;
  display: flex;
  width: 80%;
  @media (max-width: 415px) {
    width: 85%;
  }
`;
const Logo = styled.img`
  flex-direction: column;
  margin: auto 0;
  display: flex;
  height: 40px;
  flex: 0;
  @media (max-width: 900px) {
    height: 25px;
  }
`;
const NavOptions = styled.div`
  padding-top: 1%;
  display: flex;
  flex: 0.8;
  @media (max-width: 415px) {
    flex-direction: column;
    height: fit-content;
    display: flex;
    width: 100%;
    padding: 0;
    flex: 1;
  }
`;
const NavLink = styled(Link)`
  font-family: Acumin, sans-serif;
  background-color: transparent;
  text-transform: uppercase;
  border-bottom: unset;
  border-right: unset;
  border-left: unset;
  padding-bottom: 1%;
  border-top: unset;
  font-size: 16px;
  margin: 2% 5%;
  color: white;
  opacity: 0.4;
  &.active {
    border-bottom: solid 2px ${theme.white};
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  @media (max-width: 415px) {
    flex-direction: row;
    padding: 15px 25px;
    text-align: left;
    display: flex;
    &.active {
      border-left: solid 3px ${theme.white};
      border-bottom: none;
      opacity: 1;
    }
  }
`;
