import { REVIEW_IMAGE_A, REVIEW_IMAGE_B, REVIEW_IMAGE_C } from '../../values';
import { ReviewType } from './types';

export const reviews: ReviewType[] = [
  {
    text: 'Been wanting a simple tuner app without the ad clutter. Pocket Tuner is now my #1 go to for tuning my guitar.',
    image: REVIEW_IMAGE_B,
    name: 'Brandon A.',
    rating: '4.8'
  },
  {
    text: 'I was dead tired of watching charts to see when I was in profit with my crypto. Now I can focus on other tasks and get notified thanks to Profit Ping.',
    name: 'Jennifer E.',
    image: REVIEW_IMAGE_A,
    rating: '5.0'
  },
  {
    text: 'Had an issue with my account and BrainFish had it resolved before the end of the following day. Great customer service.',
    image: REVIEW_IMAGE_C,
    name: 'Steven P.',
    rating: '4.7'
  }
];
