import styled from 'styled-components';
import { theme } from '../../theme';
import React from 'react';

export const Content = () => {
  const subtext = `Whether you'd like to leave a review, provide feedback, or have a general inquiry, we'd love to hear from you. It is how we grow and a vital tool to provided better products.`;
  const title = `Let's connect`;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <SubText>{subtext}</SubText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 0.5;
  @media (max-width: 415px) {
    flex: 1;
  }
`;
const Title = styled.h2`
  font-family: Clash, sans-serif;
  color: ${theme.white};
  font-weight: normal;
  font-size: 85px;
  margin: 0;
  @media (max-width: 415px) {
    padding-top: 50px;
    font-size: 50px;
  }
`;
const SubText = styled.p`
  font-family: Acumin, sans-serif;
  color: ${theme.white};
  font-weight: normal;
  padding-top: 50px;
  line-height: 35px;
  font-size: 18px;
  opacity: 0.4;
  margin: 0;
  @media (max-width: 415px) {
    padding-top: 25px;
    line-height: 30px;
    font-size: 16px;
  }
`;
