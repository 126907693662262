import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Arrow } from '../svgs';

export const Hero = () => {
  const title = `Exceptional Software`;
  const subtitle = `Where deep technological dives result in the simplest, most intuitive software`;
  const productsLabel = `Our Products`;

  return (
    <Wrapper name="home">
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ProductsButton
        onClick={() =>
          scroller.scrollTo('products', {
            duration: 500,
            smooth: true,
            offset: 50,
            spy: true
          })
        }>
        {productsLabel}
        <Arrow />
      </ProductsButton>
    </Wrapper>
  );
};

const Wrapper = styled(Element)`
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
  align-self: center;
  max-width: 1440px;
  display: flex;
  margin: auto;
  height: 95vh;
  width: 100vw;
`;
const Title = styled.h2`
  font-family: Clash Medium, sans-serif;
  color: ${theme.white};
  flex-direction: row;
  align-self: center;
  text-align: center;
  font-size: 118px;
  display: flex;
  margin: 0;
  @media (max-width: 415px) {
    font-size: 50px;
  }
`;
const Subtitle = styled.p`
  font-family: Acumin, sans-serif;
  color: ${theme.white};
  flex-direction: row;
  align-self: center;
  font-size: 18px;
  display: flex;
  padding: 5% 0;
  opacity: 0.4;
  margin: 0;
  @media (max-width: 415px) {
    text-align: center;
    line-height: 25px;
    font-size: 16px;
    width: 70%;
  }
`;
const ProductsButton = styled.button`
  border: solid 1px ${theme.white};
  font-family: Acumin, sans-serif;
  background-color: transparent;
  text-transform: uppercase;
  color: ${theme.white};
  border-radius: 20px;
  align-items: center;
  align-self: center;
  width: fit-content;
  padding-top: 0.25%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 50px;
  font-size: 16px;
  display: flex;
  height: 50px;
  &:hover {
    border: solid 1px ${theme.background};
    background-color: ${theme.primary};
    color: ${theme.background};
    cursor: pointer;
  }
  @media (max-width: 415px) {
    font-size: 14px;
  }
`;
