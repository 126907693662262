import { ABOUT_US_IMAGE, CLOUD_A } from '../../values';
import { Element, scroller } from 'react-scroll';
import { useIsMobileHook } from '../../hooks';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Arrow } from '../svgs';
import { useMemo } from 'react';

export const About = () => {
  const { isMobile } = useIsMobileHook();

  const description = `There are countless products out in the market. However, there is a vast and high-demand need for simplicity and effectiveness. BrainFish is here to fill that void. With over 8 years of product development experience, we at Brainfish strive to provide products that user's can quickly pick-up, use effectively, and enjoy. When you use a Brainfish product, you can rest assured the product's use case is fulfilled without all the filler, obnoxious ads, and simply works.`;
  const buttonText = `our products`;
  const title = `About us`;

  const content = useMemo(
    () => (
      <>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ProductsButton
          onClick={() =>
            scroller.scrollTo('products', {
              duration: 500,
              smooth: true,
              offset: 50,
              spy: true
            })
          }>
          {buttonText}
          <Arrow color={theme.white} />
        </ProductsButton>
      </>
    ),
    [title, buttonText, description]
  );

  return (
    <Wrapper name="about">
      {!isMobile ? <Cloud src={CLOUD_A} /> : null}
      <Container>
        {isMobile ? content : <Content>{content}</Content>}
        <AboutImageContent>
          <AboutImage src={ABOUT_US_IMAGE} alt="About BrainFish" />
        </AboutImageContent>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Element)`
  flex-direction: row;
  position: relative;
  min-height: 90vh;
  display: flex;
  margin: auto;
  width: 100%;
  @media (max-width: 415px) {
    flex-direction: column;
    padding: 50px 0;
  }
`;
const Cloud = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

const Container = styled.div`
  flex-direction: row;
  max-width: 1440px;
  display: flex;
  margin: auto;
  height: 100%;
  width: 80%;
  @media (max-width: 415px) {
    justify-content: space-evenly;
    flex-direction: column;
  }
`;
const Content = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1440px;
  display: flex;
  margin: auto;
  flex: 0.5;
`;
const Title = styled.h2`
  font-family: Clash, sans-serif;
  color: ${theme.white};
  flex-direction: row;
  font-weight: 400;
  font-size: 48px;
  margin: 0;
  @media (max-width: 415px) {
    font-size: 35px;
  }
`;
const Description = styled.p`
  font-family: Acumin, sans-serif;
  color: ${theme.white};
  flex-direction: row;
  text-indent: 25px;
  line-height: 35px;
  text-align: left;
  font-size: 18px;
  padding: 5% 0;
  opacity: 0.4;
  margin: 0;
  @media (max-width: 415px) {
    font-size: 16px;
  }
`;
const ProductsButton = styled.button`
  background-color: ${theme.background};
  border: solid 1px ${theme.white};
  font-family: Acumin, sans-serif;
  padding: 15px 20px 10px 20px;
  text-transform: uppercase;
  color: ${theme.white};
  border-radius: 20px;
  align-items: center;
  width: fit-content;
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  height: 50px;
  margin: 0;
  &:hover {
    border: solid 1px ${theme.background};
    background-color: ${theme.primary};
    color: ${theme.background};
    cursor: pointer;
  }
  @media (max-width: 415px) {
    align-self: flex-end;
    font-size: 16px;
  }
`;
const AboutImageContent = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 5%;
  flex: 0.5;
  @media (max-width: 415px) {
    flex-direction: row;
    padding: 25px 0;
  }
`;
const AboutImage = styled.img`
  max-width: 100%;
  height: auto;
`;
