import { theme } from '../../theme';

export const RatingStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 17"
    fill="none"
    height="17"
    width="19">
    <path
      d="M17.6232 6.24029L12.4163 5.48355L10.0886 0.764705C10.025 0.635506 9.92046 0.530916 9.79126 0.467342C9.46723 0.307381 9.07348 0.440681 8.91147 0.764705L6.58383 5.48355L1.3769 6.24029C1.23335 6.2608 1.1021 6.32847 1.00161 6.43101C0.880124 6.55588 0.81318 6.72387 0.815487 6.89806C0.817795 7.07226 0.889164 7.23841 1.01391 7.36002L4.7812 11.033L3.89116 16.2194C3.87029 16.34 3.88364 16.4641 3.9297 16.5776C3.97576 16.691 4.05268 16.7893 4.15175 16.8612C4.25082 16.9332 4.36806 16.9759 4.49019 16.9847C4.61232 16.9934 4.73445 16.9677 4.84272 16.9105L9.50005 14.4619L14.1574 16.9105C14.2845 16.9782 14.4322 17.0007 14.5737 16.9761C14.9305 16.9146 15.1705 16.5762 15.1089 16.2194L14.2189 11.033L17.9862 7.36002C18.0887 7.25953 18.1564 7.12828 18.1769 6.98473C18.2323 6.62584 17.9821 6.29361 17.6232 6.24029V6.24029Z"
      fill={theme.primary}
    />
  </svg>
);
